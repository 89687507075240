import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error'
import { Box } from '@mui/material';
import { analyzeScan } from '@clients/shared/src/scan';
import { SubmitButton, H6 } from '../main';
import BacInput from './BacInput';

/** Wrapper combining manual input and scanner. */
export default function Universal ({handleResult}) {
  const inputRef = useRef();
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const focus = React.useCallback(
    () => {inputRef.current && inputRef.current.focus()}, []);
  const submit = async ({bac_shown_id}, {resetForm})  => (
    Promise.resolve(analyzeScan(bac_shown_id))
    .then(({bacId}) => handleResult(bacId || bac_shown_id))
    .then(() => setSucceeded(true), () => setSucceeded(false))
    .then(resetForm).then(focus));
  useEffect(() => {focus()}, [focus]);
  return (
    <Box m={4} minWidth='50%'>
      <Formik 
        onSubmit={submit} 
        initialValues={{bac_shown_id:''}}
        validateOnChange={false}
      >
        {({handleReset, dirty}) => (<Form> 
          <Box minHeight={{md:150}} mb={{xs:0, md:5}}> 
          <ConnectedFocusError />
          <H6>Ajouter un bac</H6>
          <BacInput reset={() =>{handleReset(); focus()}} inputRef={inputRef}/>
          <SubmitButton mt={0} disabled={!dirty} {...{submitSucceeded}}>
            Ajouter
          </SubmitButton>
          </Box>
        </Form>)}
      </Formik>
    </Box>
  );
};
