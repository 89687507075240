import React, { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-mui';
import { TextField } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';

/** Autocomplete field fetching suggestions. */
export function ItemAutocomplete({itemUrl, name='id', label='Nom', renderLabel,
    onInputChange, error, helperText, required, showOptions=true, ...others}) {
  const [ options, setOptions ] = useState([]);
  const [ { value } , , { setValue } ] = useField(name);
  const api = useApi({disableConcurrentRequest: true});
  const onInputChangeParent = (event, value) => {
    if (showOptions && value.length === 3) {
      api.get(itemUrl+'?text='+value+'&all=true&light_data=true')
        .then(respItems => respItems.map(item => ({id: item.user_id, ...item})))
        .then(respItems =>
          setOptions(options => mergeArrays(respItems, options))
        )
        .catch(()=>{});
    }
    onInputChange && onInputChange(event, value);
  };
  useEffect(() => {
    options.length===0 && value && 
    api.get(itemUrl+`/${value}`)
      .then(item => setOptions([{id: item.user_id, ...item}]))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemUrl, options.length, value])

  return(
    <Field
      name={name}
      component={Autocomplete}
      options={options}
      onInputChange={onInputChangeParent}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          variant="standard"
          required={required}
        />
      )}
      autoHighlight
      noOptionsText='Aucune option'
      onChange={(e, option) => setValue(option?.id || null)}
      isOptionEqualToValue={(option, value) => option?.id === value}
      getOptionLabel={()=>renderLabel(options.find(o => o?.id === value)) || ''}
      {...others}
    />);
};

const mergeArrays = (array1, array2) => Array.from(
  new Map([...array1, ...array2].map(obj => [obj.id , obj]))
  .values()
)
