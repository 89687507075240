import React from 'react';
import { Grid, Card, Box, ListItemButton, ListItemIcon, ListItemText,
  } from '@mui/material';
import { SsidChart, Storefront, AcUnit, CloudDownload, NotListedLocation
  } from '@mui/icons-material';
import { stdDate } from '@clients/shared/src/utils';
import { usePlaces, useWorkplace 
} from '@clients/shared/src/place/services';
import { useUserIs } from '@clients/shared/src/user/services';
import useToursState from '@clients/shared/src/tour/toursState';
import { Link, Page, makeUrl } from '../main';
import { SearchBar, useSearch } from '../searchbar';
import PositionsAnalytics from './PositionsAnalytics';


/** Page showing an overall view of bac positions. */
export default function Analytics () {
  const workplace = useWorkplace();
  const userIs = useUserIs();
  const isSuper = userIs('superadmin');
  const destinations = usePlaces();
  const { tours } = useToursState(workplace.id);
  const filters = [
    {template: 'workplace'},
    {template: 'workplace',
      label: 'Clients',
      items: destinations,
      hide: !isSuper
    },
    {template: 'tours', items: tours}
  ];
  const [searchBarProps, ] = useSearch(filters, []);
  const links =[
    {
      to: makeUrl('activity', {workplace_id: workplace.id}), 
      text: 'Activité',
      icon: <SsidChart/>,
    },
    {
      to: makeUrl('/reports/places',
        {after: stdDate(-33), before: stdDate(-2), workplace_id: workplace.id}),
      text: 'Chez clients',
      icon: <Storefront/>,
    },
    {
      to: makeUrl('/reports/immobilizations',
        {after: stdDate(-33), before: stdDate(-2), workplace_id: workplace.id}),
      text: 'Bacs immobilisés',
      icon: <AcUnit/>,
    },
    {
      to: makeUrl('/reports/lost', {workplace_id: workplace.id}),
      text: 'Bacs perdus',
      icon: <NotListedLocation sx={{fontSize: 25}}/>,
    },
    {
      to: makeUrl('/export/'+workplace.id), 
      text: 'Exporter les données',
      icon: <CloudDownload/>,
    },
  ];

  return (
    <Page title='Synthèse des stocks'>
      <SearchBar {...searchBarProps}/>
      <Grid container 
        justifyContent='center'
        alignItems='center'
        spacing={2}
        py={4} px={2}
      >
        <PositionsAnalytics place={workplace}/>
      </Grid>
      <Grid item>
        <Box component={Card} 
          width={{xs:0.9, md:0.5}} 
          my={{xs:4, md:8}} 
          mx='auto'
          p={2}
        >
          {links.map(({to, text, icon}) =>
          <ListItemButton key={text} component={Link} to={to}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </ListItemButton>
          )}
        </Box>
      </Grid>
    </Page>
  );
}
