import React from 'react';
import { styled } from '@mui/system';
import { FastField, useField, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { analyzeScan } from '@clients/shared/src/scan';


/** An input field to handle bac shown id or a bac URL. */
export default function BacInput ({reset, inputRef, ...others}) {
  const [field, ] = useField('bac_shown_id');
  const { handleSubmit } = useFormikContext();
  const handleInputChange = event => {
    field.onChange(event)
    const { bacId } = analyzeScan(event.target.value)
    bacId && handleSubmit()
  };
  return (
    <FastField
      name='bac_shown_id'
      id='manual-input'
      component={TextField}
      placeholder='N° du bac'
      required
      autoComplete='off'
      variant='outlined'
      fullWidth
      margin='normal'
      InputProps={{
        endAdornment: 
          <StyledIconButton onClick={reset}>
            <Cancel />
          </StyledIconButton>
      }}
      inputRef={inputRef}
      onChange={handleInputChange}
      {...others}
    />
  );
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '&:hover': {
    background: 'none',
    color: '#000000'
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
}));
