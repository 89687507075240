import React from 'react';

import Profile from '../user/Profile';
import Users from '../user/Users';
import User from '../user/User';
import VisibleUser from '../visibleUser/VisibleUser';
import Workplaces from '../user/Workplaces';
import Password from '../user/Password';
import CreateUser from '../user/Create';

import Place from '../place/Place';
import Places from '../place/Places';
import PlaceSettings from '../place/Settings';
import PlaceAddBac from '../place/AddBac';
import PlaceAddRef from '../place/AddRef';
import PlaceAnalytics from '../place/PlaceAnalytics';
import DestinationsAnalytics from '../place/DestinationsAnalytics';
import LostAnalytics from '../place/LostAnalytics';
import PlacesEdit from '../place/PlacesEdit';

import PlaceSearch from '../place/PlaceSearch';

import Billing from '../place/Billing';
import DataExport from '../place/DataExport';

import History from '../event/History';
import Events from '../event/Events';
import Returns from '../event/Returns';

import Bac from '../bacs/Bac';
import BacsAnalytics from '../bacs/Analytics';
import LeasedAnalysis from '../bacs/PlaceBacLeasedAnalysis';
import Activity from '../bacs/Activity';
import Immobilizations from '../bacs/Immobilizations';

import LeasingMenu  from '../leases/LeasingMenu';
import Model from '../models/Model';
import Models from '../models/Models';
import Lease from '../leases/Lease';
import Leases from '../leases/Leases';

import MessageSettings from '../messages/MessageSettings';
import MessagesSettings from '../messages/MessagesSettings';
import SituationMail from '../messages/Situation';

import QRCreator from '../qr/CreateQR';
import QR from '../qr/QR';

import Superadmin from '../main/Superadmin';

import Tour from '../tour/Tour';
import Tours from '../tour/Tours';

import Pickup from '../pickup/Pickup';
import Pickups from '../pickup/Pickups';
import StockTrigger from '../pickup/StockTrigger';
import StockTriggers from '../pickup/StockTriggers';

import Role from '../roles/Role';
import Roles from '../roles/Roles';

import { PrivateRoute } from '../_utils/PrivateRoute';

/** List of private routes. */
export default function PrivatePages () {
  return <>
    {pages.map(({path, component, exact}) => 
      <PrivateRoute key={path} {...{path, component, exact}}/>)}
    </>
};

/** List of private urls and the associated component. */
const pages = [
  {path: '/places', component: Places, exact: true},
  {path: '/places/create', component: PlaceSettings},
  {path: '/places/:place_id(\\d+)', component: Place, exact: true},
  {path: '/places/:place_id(\\d+)/edit', component: PlaceSettings},
  {path: '/places/:place_id(\\d+)/bacs', component: PlaceAddBac},
  {path: '/places/:place_id(\\d+)/placerefs', component: PlaceAddRef},
  {path: '/places/:place_id(\\d+)/billing', component: Billing},
  {path: '/places/:place_id(\\d+)/users/create', component: CreateUser},
  {path: '/places/:place_id(\\d+)/visible-users/create',component: VisibleUser},
  {path: '/places/:place_id(\\d+)/get-bacs-analysis', component:LeasedAnalysis},
  {path: '/places/edit', component: PlacesEdit},
  {path: '/reports/places', component: DestinationsAnalytics, exact: true},
  {path: '/reports/places/:place_id(\\d+)', component: PlaceAnalytics},
  {path: '/reports/bacs', component: BacsAnalytics},
  {path: '/reports/activity', component: Activity},
  {path: '/reports/lost', component: LostAnalytics},
  {path: '/reports/immobilizations', component: Immobilizations},
  {path: '/events/history', component: History},
  {path: '/events', component: Events, exact: true},
  {path: '/returns', component: Returns},
  {path: '/bacs/:bac_id([A-Za-z0-9]+)', component: Bac},
  {path: '/leasing', component: LeasingMenu},
  {path: '/models', component: Models, exact: true},
  {path: '/models/create', component: Model},
  {path: '/models/:model_id(\\d+)', component: Model},
  {path: '/leases', component: Leases, exact: true},
  {path: '/leases/create', component: Lease},
  {path: '/leases/:lease_id(\\d+)', component: Lease},
  {path: '/users/profile', component: Profile},
  {path: '/users/password', component: Password},
  {path: '/users/:user_id(\\d+)/password', component: Password},
  {path: '/users', component: Users, exact: true},
  {path: '/users/:user_id(\\d+)', component: User, exact: true},
  {path: '/users/:user_id(\\d+)/workplaces', component: Workplaces},
  {path: '/visible-users/:visible_id(\\d+)', component: VisibleUser},
  {path: '/qr', component: QR, exact: true},
  {path: '/qr/:page_name(\\S+)', component: QRCreator, exact: true},
  {path: '/superadmin', component: Superadmin},
  {path: '/messages-settings', component: MessagesSettings, exact: true},
  {path: '/messages-settings/:settings_id(\\d+)', component: MessageSettings},
  {path: '/messages-settings/create', component: MessageSettings},
  {path: '/situation-mails', component: SituationMail},
  {path: '/tours', component: Tours, exact: true},
  {path: '/tours/:tour_id(\\d+)', component: Tour},
  {path: '/tours/create', component: Tour},
  {path: '/export/:place_id(\\d+)', component: DataExport},
  {path: '/search/places', component: PlaceSearch},
  {path: '/stock-triggers', component: StockTriggers, exact: true},
  {path: '/stock-triggers/create', component: StockTrigger},
  {path: '/stock-triggers/:stock_trigger_id(\\d+)', component: StockTrigger},
  {path: '/pickups', component: Pickups, exact: true},
  {path: '/pickups/create', component: Pickup},
  {path: '/pickups/:pickup_id(\\d+)', component: Pickup},
  {path: '/roles', component: Roles, exact: true},
  {path: '/roles/create', component: Role},
  {path: '/roles/:role_id(\\d+)', component: Role},
];
