import React from 'react';
import { Field, useField } from 'formik';
import { TextField, Switch } from 'formik-mui';
import { Grid, Typography, MenuItem } from '@mui/material';
import { DatePicker, DateTimePicker } from '../main';


const DateField = (props) => <DatePicker disableFuture xs={true} {...props} />

const DateTimeField = (props) => 
  <DateTimePicker disableFuture xs={true} {...props} />

const SelectField = ({name, items, label}) => (
  <Grid item xs>
    <Field 
      component={TextField}
      type='text'
      name={name}
      label={label}
      select
      variant="standard"
      fullWidth
      sx={{textAlign: 'left'}}
    >
      {turnToValidOptions(items).map(({value, label}) =>
      <MenuItem key={label} value={value}>
        <Typography variant='body1'>{label}</Typography>
      </MenuItem>)}
    </Field>
  </Grid>
);

const BooleanSelectField = ({name, trueName, falseName}) => (
  <SelectField 
    name={name}
    label={trueName + ' ou ' + falseName.toLowerCase()}
    items={[
      {value: '', label: 'Non défini'},
      {value: true, label: trueName},
      {value: false, label: falseName},
    ]}
  />
)

const SwitchField = ({name, label, disabled}) => {
  const [,, { setValue }] = useField(name);
  return (
    <Grid item xs sx={{textAlign: 'left', marginLeft: '-10px'}} >
      <Field
        component={Switch}
        {...{name, disabled}}
        type='checkbox'
        color='primary'
        onChange={(e, checked) => setValue(checked ? true : '')}
      />
      <label>{label}</label>
    </Grid>)
};

/*** Turn items with id and name into valid options */
export const turnToValidOptions = items =>
  items?.map(({value, label, id, name}) => ({
    'value': (value !== undefined) ? value : id,
    'label': label || name
  }))


export {
  DateField,
  DateTimeField,
  SelectField,
  SwitchField,
  BooleanSelectField,
}
