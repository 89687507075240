import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Card, Grid, Chip, ButtonBase } from '@mui/material';
import { Place as PlaceIcon, ShoppingBasket, Keyboard, KeyboardHide, ViewWeek, 
  LocalShipping} from '@mui/icons-material';
import { useAddEvent, useSimilarRecentEvents 
  } from '@clients/shared/src/event/services';
import { pluralize } from '@clients/shared/src/utils';
import { useTours } from '@clients/shared/src/tour/services';
import { useCurrentUser } from '@clients/shared/src/user/services';
import { H6 } from '../main';
import Universal from '../scan/Universal';
import EventsGlance from '../event/EventsGlance';


/** Manage deliveries of a place. */
export default function PlaceContent ({place}) {
  const { id: destination_id } = place || {};
  const events = useSimilarRecentEvents(place)
  const addEvent = useAddEvent();
  const [showKb, setShowKb] = useState(localStorage.getItem('showKb')==='true');
  const toggleKeyboard = () => setShowKb(showKb => {
    localStorage.setItem('showKb', (!showKb).toString());
    return !showKb});
  const onScan = bacId => addEvent(bacId, {destination_id});
  // TODO: traiter le cas où bacEmpty n'est pas défini dans le store (accés 
  // direct à une page de scan): le définir via un modal ?
  return (
    <StyledGrid item xs container className={classes.content}>
      <Grid item md={7} container
        direction='column' 
        alignItems='center'
        justifyContent='start'
        className={classes.input}
      > 
        <PlaceDetails {...{place}}/>
        <ButtonBase onClick={toggleKeyboard} className={classes.button}>
          {showKb ? <KeyboardHide/> : <Keyboard/>}
        </ButtonBase>
        {showKb && <Universal handleResult={onScan} />}
      </Grid>
      <EventsGlance events={events}/>
    </StyledGrid>
  );
}

const PlaceDetails = ({place}) => {
  const isSending = useCurrentUser().workplace_id !== place.id;
  const tours = useTours().filter(tour => 
    tour.places.some(p => p.id === place.id));
  return (
    <>
      <Grid container
        component={Card} 
        justifyContent='center' 
        alignItems='center'
        className={classes.card} 
      > 
        <ShoppingBasket sx={{marginRight: 2}}/>
        <H6>{pluralize('bac', place.stock)} sur place</H6>
      </Grid>
      {isSending && <Grid container
        component={Card}
        className={classes.card}
        direction='column'
        alignItems={{xs: 'center', md: 'flex-start'}}
        rowSpacing={2}
      >
        {place.ref && <Grid item container>
          <Grid item component={ViewWeek} className={classes.detailsIcons}/>
          <Grid item><H6>{place.ref}</H6></Grid>
        </Grid>}
        <Grid item container>
          <Grid item component={PlaceIcon} className={classes.detailsIcons}/>
          <Grid item container direction='column'>
            <H6>{place.address || ''}</H6>
            <H6>{place.postal_code || ''} {place.city || ''}</H6>
          </Grid>
        </Grid>
        {tours.length > 0 && <Grid item container>
          <Grid item component={LocalShipping}
            className={classes.detailsIcons}
          />
          <Grid item container direction={'column'}>
            {tours.map(tour => 
              <Chip 
                key={tour.id} 
                label={tour.name} 
                variant='outlined'
                sx={{fontSize: 18, borderColor: 'black', marginBottom: 0.5}}
              />
            )} 
          </Grid>
        </Grid>}
      </Grid>}
    </>
  )
}


const PREFIX = 'PlaceContent';

const classes = {
  content: `${PREFIX}-content`,
  input: `${PREFIX}-input`,
  card: `${PREFIX}-card`,
  detailsIcons: `${PREFIX}-detailsIcons`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.content}`]: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  [`& .${classes.input}`]: {
    padding: theme.spacing(12, 0),
    rowGap: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing(4),
      padding: theme.spacing(0),
      flexDirection: 'column-reverse',
      width: '100%',
      maxWidth: 'none',
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.card}`]: {
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: 0,
      boxShadow: theme.components.shadows.defaultProps.outer,
    },
  },

  [`& .${classes.detailsIcons}`]: {
    marginRight: 12,
  },

  [`& .${classes.button}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    width: 65,
    height: 65,
    borderRadius: '50%',
    '& .MuiSvgIcon-root': {
      fontSize: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: 35,
      height: 35,
      '& .MuiSvgIcon-root': {
        fontSize: 25,
      },
    },
  }
}));
