/** Sum all digits of a number. */
function reduce(code, ponderation) {
  if (typeof(code)==='number' && code<10) {
    return code;
  } else if (typeof(code)==='number') {
    return reduce(String(code));
  }
  let sum = 0;
  for (var k=0; k<code.length; k++) {
    let d = Number(code[k])
    if (k % 2 === 0) { d *= (ponderation || 1) }
    //if (d > 9) { d = reduce(String(d)) }
    sum += d
  }
  return sum
};

/** Generate the code with the check digit to be used a bac_shown_id. */
function generate_code(number, digits, prefix) {
  let sum = 0;
  if (prefix) {
    //sum += reduce(prefix.charCodeAt() - 'A'.charCodeAt() + 1);
    sum += prefix.charCodeAt() - 'A'.charCodeAt() + 1;
  }
  const code = (Array(digits+1).join('0') + number).slice(-digits);
  // I don't use repeat for IE compatibility
  //const reverse = ('' + sum + code).split('').reverse().join('')
  const reverse = code.split('').reverse().join('')
  //sum = reduce(reverse, 3);
  sum += reduce(reverse, 3);
  sum = sum % 10;
  const check_digit = sum===0 ? 0 : 10 - sum;
  return ( (prefix || '') + code + check_digit );
};

/** Check if string is a valid bac shown id. */
const formatBacId = (value) => {
  if (value===undefined || value===null || value==='') {
    return "Un code ne peut être vide."
  } else if (/[^a-zA-Z0-9]/.test(value)) {
    return "Un code n'est composé que de chiffres et de lettres."
  } else if (value.length === 5) {
    return validateGatine(value)
  } else if (value.length<12) {
    return validatePandoBacId(value)
  } else if (value.length<44) {
    return validateGRAI(value)
  } else {
    return "Un code ne peut pas être si long."
  }
};

/** Check if string is a valid Pando's bac shown id. */
const validatePandoBacId = (value) => {
  if (value.length!==7) {
    return "Un code Pandobac doit contenir 7 caractères."
  } else if (/^[^A-Z]/.test(value)) {
    return "Un code Pandobac doit commencer par une lettre majuscule."
  } else if (/[0-9]{6}$/.test(value)===false) {
    return "Un code Pandobac se termine par 6 chiffres."
  } 
  const n = parseInt(value.slice(1,6), 10);
  if (value[0]==='V' && n<113) {
    return undefined
  }
  if (value!==generate_code(n, 5, value[0])) {
    return "Ce code Pandobac est invalide."
  }
};

/** Check if string is a valid GRAI. */
const validateGRAI = (value) => {
  if (/[^0-9]/.test(value)) {
    return "Un code GRAI est composé uniquement de chiffres."
  } else if (value.startsWith("8003")===false) {
    return "Un code GRAI doit commencer par '8003'."
  } else if (value.length<23) {
    return "Un code GRAI est composé d'au moins 22 chiffres."
  }
};

/** Check if string is a valid Gatine id. */
const validateGatine = (value) => {
  if (/[^A-Z]$/.test(value)) {
    return "Un code Gatine doit terminer par une lettre majuscule."
  } else if (!/^\d{4}[A-Z]$/.test(value)){
    return "Un code Gatine est composé de 4 chiffres + 1 lettre."
  }
}

export { generate_code, formatBacId }
