import { useMemo } from 'react';
import { useUserLinks } from './menuLinks';


export default function useLinks () {
  const userLinks = useUserLinks();
  return useMemo(
    () => userLinks.filter(link => link.web)
      .map(({ label, bacEmpty, bacClean, web }) => ({
        label,
        to: { 
          pathname: web.uri,
          search: web.search || '', 
          state: { bacEmpty, bacClean }
        }
      }))
  , [userLinks]);
}
