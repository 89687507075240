import { useUserLinks } from "../links/menuLinks";
import { useGetBacClean, useGetBacEmpty } from "../scan/services";


export default function useGetReturnTitle () {
    const isClean = useGetBacClean();
    const isEmpty = useGetBacEmpty();
    const returnLinks = useUserLinks().filter(l => l.isReturn);
    return returnLinks.find(l => 
        l.bacClean===isClean && l.bacEmpty===isEmpty
    )?.label;
}
