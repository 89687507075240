import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Box } from '@mui/material';
import qs from 'query-string';
import { useApi } from '@clients/shared/src/utils';
import {useAddPlace, usePlaces} from '@clients/shared/src/place/services';
import { SubmitButton, Card, makeUrl } from '../main';
import PlaceAutocomplete from './PlaceAutocomplete'

/** Component to edit an existing place or add a place to destinations. */
export default function PlaceForm ({place, setPlace, history}) {
  const addPlace = useAddPlace();
  const location = useLocation();
  const [ selectedPlace, setSelectedPlace ] = useState({});
  const ownDestinations = usePlaces();
  const [ workplaceDestinations, setWorkplaceDestinations ] = useState([]);
  const { workplace_id } = qs.parse(location.search, {parseNumbers: true});
  const destinations = workplace_id ? workplaceDestinations : ownDestinations;
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const api = useApi({disableConcurrentRequest: true});
  const isEditing = place.id !== undefined;

  useEffect(() => {
    workplace_id && api.get('/places?workplace_id=' + workplace_id)
      .then(setWorkplaceDestinations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [workplace_id]);

  const submit = (values, form) => {
    if (isEditing) {
      return api.put('/places/' + values.id, values).then(setPlace)
        .then(() => setSucceeded(true), e=>({e}));
    }
    const pristine = !isDirty(values, selectedPlace);
    const urn = (pristine ? '/destinations' : '/places');
    if (workplace_id) {
      return api.post(urn + '?workplace_id=' + workplace_id, values)
        .then(newPlace => history.replace(
          makeUrl('/places/edit', {workplace_id, text: newPlace.name}),
        ));
    }
    return api.post(urn, values).then(place => {
      const newPlace = pristine ? values : place;
      addPlace({...newPlace, created: (new Date()).toJSON()});
      history.replace('/places/'+ newPlace.id +'/edit');
    });
  };

  const validate = values => {
    const errors = {};
    if (!isEditing && !isDirty(values, selectedPlace)
      && destinations.some(dest => dest.id === values.id)) {
      errors.place = 'Déjà un point de livraison.';
    }
    if (!values.name) {
      errors.name = 'Un lien a besoin d\'un nom';
      errors.place = errors.name;
    }
    return errors;
  };

  const initialValues = (() => {
    const empty = {name:'', address:'', postal_code:'', city:'', place_id:''};
    if (isEditing) {
      return {...empty, ...place};
    } else if (selectedPlace.id) {
      return {...selectedPlace};
    } else {
      return {...empty};
    }})();

  return(
    <Card title='Informations sur le client'>
      <Formik
        onSubmit={submit} 
        initialValues={initialValues}
        enableReinitialize
        validate={validate}
      >
        {({dirty, submitting, errors, touched, setFieldValue}) => (
        <Box mt={4} component={Form}>
          {!isEditing && 
            <PlaceAutocomplete 
              onChange={(e, value) => (value &&
                setSelectedPlace({...value, place_id: value.id}))}
              onInputChange={(e, value) => setFieldValue('name', value)} 
              freeSolo
              required
              label='Nom'
              error={touched.id && !!errors.place}
              helperText={errors.place}
            />}
          {[
            {name:'name', label:'Nom', hide: !isEditing},
            {name:'address', label:'Adresse'},
            {name:'postal_code', label:'Code Postal'},
            {name:'city', label:'Ville'},
          ].filter(field => !field.hide).map(({name, label}) => 
          <Field
              key={name}
              component={TextField}
              name={name}
              label={label}
              required
              margin='dense'
              variant="standard"
              fullWidth
            />
          )}
          <SubmitButton 
            {...{submitSucceeded}}
            disabled={submitting || 
              (!dirty && (isEditing || !selectedPlace.id))}
          >
            {!isEditing && (!dirty ? 'Ajouter' : 'Créer et ajouter')}
            {isEditing && 'Mettre à jour'}
          </SubmitButton>
        </Box>
        )}
      </Formik>
    </Card>
  );
};

const isDirty = (values, {name, address, city, postal_code}) => (
  values.name!==name || values.address!==address ||
  values.city!==city || values.postal_code!==postal_code);
