const cleanReadRE = /^[^;?\n]+/ //keep text before special caracter
const validHost = /(?<host>pando.ovh)(?<pathname>\/[^\s]*)?/i
const qwertyHost = /pqndo\/ovh/i
const graiCode = /8003(?<owner>\d{8})(?<model>\d{5})(?<key>\d{1})(?<id>\d{5,})/
const gatineCode39 = /(\*)?(?<id>^\d{4}S$)(\*)?/


/* Analyzes a string to see if it contains valid barcode. */
export default function analyzeScan (input = '') {
  const text = input.match(cleanReadRE)?.at(0) || '';
  const pandoURLScan = analyzePandoURL(text);
  if (pandoURLScan.pathname){
    return {...pandoURLScan, input};
  }
  if (qwertyHost.test(text)) {
    const azertyText = input.split('').map(c => qwertyToAzerty[c] || c).join('');
    return {...analyzePandoURL(azertyText), input};
  } 
  let bacId = text.match(graiCode)?.groups?.id;
  if (bacId) {
    return {bacId, input};
  }
  bacId = text.match(gatineCode39)?.groups?.id;
  if (bacId) {
    return {bacId, input};
  }
  return {error: `${input} doesn't contain a valid code.`, input};
}

/* Analyzes a string to see if it is a pando url. */
const analyzePandoURL = text => {
  const { host, pathname } = text?.match(validHost)?.groups || {};
  const { urlId } = pathname?.match(/\/bacs\/(?<urlId>[^\s]*)/i)?.groups || {};
  const { formatedId }=urlId?.match(/(?<formatedId>[A-Z]\d{6})/i)?.groups || {};
  if (!host) {
    return { error: `${text} doesn't contain pando.ovh url.` };
  } else if (!urlId){
    return { error: `${pathname} is not a bac URL.`, pathname };
  } else if (!formatedId) {
    return { error: `${urlId} wrong format (1 letter + 6 digits).`, pathname };
  }
  return { bacId: formatedId, pathname };
}

const qwertyToAzerty = {
    'a': 'q', 'z': 'w', 'q': 'a', 'w': 'z', 
    'm': ',', ',': 'm', ';': 'm', ':': 'M',
    'A': 'Q', 'Z': 'W', 'Q': 'A', 'W': 'Z',
    '/':'.', '§':'/', 'm':':', 
};


//* TESTS *//*
// console.log(analyzeScan("qsjldqsdljk"))
// console.log(analyzeScan("https://pando.ovh/pla-%%ces/SFD"))
// console.log(analyzeScan("pando.ovh/bacs/A075816"))
// console.log(analyzeScan("www.pando.ovh/bacs/A075816"))
// console.log(analyzeScan("www.truc.ovh/bacs/A075816"))
// console.log(analyzeScan("www.pando.ovh/truc/A075816"))
// console.log(analyzeScan("www.pando.ovh/bacs/A12345"))
// console.log(analyzeScan("httpsm§§pqndo/ovh§bqcs§B465135"))
// console.log(analyzeScan("httpsm§§pqndo/ovh§bqcs§Q000999"))
// console.log(analyzeScan("8003087145481560070000113037")) //ok
// console.log(analyzeScan("8003087145481560070000113037A")) //ok
// console.log(analyzeScan("1348003087145481860040025787121")) //ok
// console.log(analyzeScan("2003087145481860040025787121")) //nok
// console.log(analyzeScan("80030871454X815600700113037")) //nok
// console.log(analyzeScan("5192S")) //ok
// console.log(analyzeScan("1234A")) //nok
// console.log(analyzeScan("123A")) //nok
// console.log(analyzeScan("A1234")) //nok
