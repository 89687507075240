import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Grid } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { Load } from '../_utils';
import PositionsTable from './PositionsTable';
import PositionsDoughnut from './PositionsDoughnut';

/** Bacs positions analytics. */
export default function PositionsAnalytics ({place}) {
  const api = useApi();
  const location = useLocation();
  const [ positions, setPositions ] = useState({models:[], places:[]});
  const { models, places, places_not_clean: placesNotClean } = positions;
  useEffect(() => {api.get().then(setPositions, ()=>{});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  models.sort((modA, modB) => (modA.name > modB.name) ? 1 : -1);

  const currentPlace = places.find(({id}) => id===place.id) || {};
  const currentNotClean = placesNotClean?.find(({id}) => id===place.id) || {};
  const otherPlaces = places.filter(({name, id}) => name && id!==place.id);
  const othersNotClean = 
    placesNotClean?.filter(({name, id}) => name && id!==place.id);
  const getByDescription = desc =>
    places.find(place => place.description===desc) || {};
  const data = [
    {
      label:'En stock', key:'stock',
      data: models.map(({id}) => currentPlace[id] || 0),
    }, {
      label:'En stock sale', key:'stockNotClean',
      data: models.map(({id}) => currentNotClean[id] || 0),
    }, {
      label:'Livrés', key:'clients',
      data: models.map(model => 
        otherPlaces.reduce((tot, place) => tot + (place[model.id] || 0), 0)
        + othersNotClean.reduce((tot, place) => tot + (place[model.id] || 0), 0)
      )
    }, {
      label:'Perdus', key:'lost',
      data: models.map(({id}) => getByDescription('lost')[id] || 0),
    }, {
      label:'Cassés', key:'broken',
      data: models.map(({id}) => getByDescription('broken')[id] || 0),
    }, {
      label:'Remisés', key:'bac remisé',
      data: models.map(({id}) => getByDescription('bac remisé')[id] || 0),
    },
  ].map(row => ({...row, total: sum(row.data)}))
    .filter(row => row.total > 0);
  const total = positions.places.reduce((tot, place) => (
    tot + models.reduce((m, model) => m + (place[model.id] || 0), 0)),
    0);
  return (
    <>
      <Grid item 
        xs={12}
        md={Math.min(12, 4+models.length)} 
        xl={Math.min(8, 3+models.length)}
      >
        <Load isLoading={api.isLoading}>
          {total>0 
            ? <PositionsTable {...{data, models, places, place}}/>
            : 'Aucun bac trouvé.'}
        </Load>
      </Grid>
      <Grid item sm={4}>
        <Load isLoading={api.isLoading}>
          <PositionsDoughnut data={data}/>
          <div style={totalStyle}>
            {total || null}
          </div>
        </Load>
      </Grid>
  </>);
};

const sum = list => list.reduce((sum, a) => sum + a, 0);

const totalStyle = {
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    marginTop: '-10px',
    height: '20px',
    lineHeight: '20px',
    fontWeight: 'bold',
  };
