import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { addPlace, loadPlaces, setPlace, deletePlace, setPlacesLoadRequired,
  setPlacesIsLoading, initializePlaces} from '../redux/actions';
import { getPlacesList, getPlacesById, getPlace, getPlacesLoadRequired,
  getPlacesIsLoading } from '../redux/selectors';
import { useCurrentUser } from '../user/services';
import { useApi, deviceStorage, NONE_FILTER } from '../utils';


// Places services

/** Load places in store hook. */
export function useLoadPlaces () {
  const dispatch = useDispatch();
  const api = useApi();
  const isLoading = usePlacesIsLoading();
  return [
    () => {
      dispatch(setPlacesIsLoading(true));
      deviceStorage.getItem('places').then(JSON.parse)
        .then(places => places && dispatch(loadPlaces(places)))
        .then(() => dispatch(setPlacesLoadRequired(false)))
        .then(() => api.get('/places'))
        .then(places => dispatch(loadPlaces(places)))
        .finally(() => dispatch(setPlacesIsLoading(false)))
    },
    isLoading,
  ];
};

/** Set load of places is required on 'true' in store hook. */
export function useRequirePlacesLoad() {
  const dispatch = useDispatch();
  return () => dispatch(setPlacesLoadRequired(true));
}

/** Initialize places in store hook */
export function useInitializePlaces() {
  const dispatch = useDispatch();
  return () => dispatch(initializePlaces());
}

/** Get if load of places is required from store hook. */
export function useGetPlacesLoadRequired() {
  return useSelector(state => getPlacesLoadRequired(state))
}

/** Get if load of places is pending from store hook. */
export function usePlacesIsLoading() {
  return useSelector(state => getPlacesIsLoading(state));
}

/** Add a place in store hook. */
export function useAddPlace () {
  const dispatch = useDispatch();
  return place => dispatch(addPlace(place));
};

/** Get the list of places from store hook. */
export function usePlaces () {
  return useSelector(state => getPlacesList(state));
};

/** Get the list of places by Ids from store hook. */
export function usePlacesById () {
  return useSelector(state => getPlacesById(state));
};

/** Get a place from store hook. */
export function usePlace (placeId) {
  return useSelector(state => getPlace(state, placeId));
};

/** Set a place in store hook. */
export function useSetPlace() {
  const dispatch = useDispatch();
  return place => dispatch(setPlace(place));
};

/** Update a place in store hook. */
export function useUpdatePlace() {
  const setPlace = useSetPlace();
  const placesD = useSelector(state => getPlacesById(state));
  return (placeId, key, value) => setPlace({...placesD[placeId], [key]: value});
};

/** Delete a place from store hook. */
export function useDeletePlace() {
  const dispatch = useDispatch();
  return (id) => dispatch(deletePlace(id));
};

/** Handle a place in store if it exists or in state otherwise. */
export function usePlaceOrState(placeId, forceUpdate) {
  const api = useApi();
  const storePlace = usePlace(placeId);
  const setPlace = useSetPlace();
  const setStorePlace = place => setPlace({ref: storePlace.ref, ...place});
  const [ statePlace, setStatePlace ] = useState({id: placeId});
  const setter = storePlace ? setStorePlace : setStatePlace;
  useEffect(() => {
    if (placeId && placeId !== NONE_FILTER && (!storePlace || forceUpdate)) {
      api.get('/places/'+placeId).then(setter);
    }
  }, [placeId])
  return [(storePlace || statePlace), setter];
};

/** Handle places in store if they exist or in state otherwise. */
export const usePlacesOrState = (placeId, options) => {
  const api = useApi();
  const { workplace_id } = useCurrentUser();
  const isFromStore = placeId === workplace_id;
  const [statePlaces, setStatePlaces] = useState([]);
  const storePlaces = usePlaces();
  const dispatch = useDispatch();
  const setStorePlace = useSetPlace();
  const deleteStorePlace = useDeletePlace();
  useEffect(() => {
    if (!isFromStore || options.include){
      api.get('/places?'+ qs.stringify({...options, workplace_id: placeId}))
        .then(isFromStore
          ? places => dispatch(loadPlaces(places))
          : setStatePlaces
        );
    }
  }, [placeId, isFromStore, options.include]);
  const places = isFromStore ? storePlaces : statePlaces;
  const updatePlace = isFromStore
    ? setStorePlace
    : place => setStatePlaces(places =>
        places.map(p => p.id === place.id ? place : p));
  const deletePlace = isFromStore
    ? place => deleteStorePlace(place.id)
    : place => setStatePlaces(places => 
        places.filter(({id}) => id !== place.id));
  return {places, updatePlace, deletePlace};
}

/** Return the place from the url else the current user's worplace. */
export function useWorkplace() {
  const api = useApi();
  const user = useCurrentUser();
  const { search } = useLocation();
  const { workplace_id } = qs.parse(search, {parseNumbers: true});
  const mainWorkplace = {id: user.workplace_id, name: user.workplace};
  const [ place, setPlace ] = 
    useState(workplace_id ? {id: workplace_id} : mainWorkplace);
  const workplaces = [...user.workplaces, mainWorkplace];
  const i = workplaces.findIndex(({id}) => id === workplace_id);
  useEffect(() => {
    if (workplace_id) {
      (i === -1)
        ? api.get('/places/' + workplace_id).then(setPlace, ()=>{})
        : setPlace(workplaces[i]);
    } else {
      setPlace(mainWorkplace);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace_id, i]);
  return place;
}

/*** Load refs and add them to places in store */
export const useAddPlaceRefs = () => {
  const api = useApi({disableConcurrentRequest: true });
  const places = usePlaces();
  const dispatch = useDispatch();
  return [
    () => {api.get('/placerefs')
      .then(refs => places.map( place => {
        place.ref = refs.find(({target_id}) => target_id === place.id)?.ref;
        return place
      }))
      .then(places => {dispatch(loadPlaces(places))})
    },
    api.isLoading
  ]
}
